import React, { useState, useEffect, useRef } from "react";
import ReactGA from "react-ga4";
import ChatMessage from "./ChatMessage";
import Header from "./Header";
import Spinner from "./Spinner";
import { ErrorBoundary } from "react-error-boundary";
import { parse } from "stacktrace-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faMicrophone,
  faComments,
  faGlobe,
  faShareAlt,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import useSpeechRecognition from "../hooks/useSpeechRecognition";

// Microphone button styling + animation
const micButtonStyle = (isListening) => ({
  position: "absolute",
  right: "10px",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 2,
  backgroundColor: "#333",
  color: "#fff",
  border: "none",
  cursor: "pointer",
  padding: "8px 12px",
  borderRadius: "4px",
  animation: isListening ? "micPulse 1.3s infinite" : "none",
});

const ChatApp = () => {
  /**
   * Store each prompt + answer as one conversation entry:
   * { prompt, answer }
   */
  const [conversations, setConversations] = useState([]);
  const [selectedConvIndex, setSelectedConvIndex] = useState(null);

  const [inputText, setInputText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [rateLimitError, setRateLimitError] = useState(null);

  // Which model is selected
  const [selectedOption, setSelectedOption] = useState("search");

  // Sidebar open or collapsed (DEFAULT: collapsed -> false)
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Speech recognition
  const {
    isListening,
    transcript,
    error: speechError,
    setIsListening,
  } = useSpeechRecognition();

  // If user used speech, set that in the text input
  useEffect(() => {
    if (transcript) {
      setInputText(transcript);
    }
  }, [transcript]);

  // Initialize Google Analytics on mount
  useEffect(() => {
    ReactGA.initialize("G-NFKD8XWG73");
    ReactGA.send("pageview");
  }, []);

  const handleUserMessage = (message) => {
    // Example analytics tracking
    ReactGA.event({
      category: "User",
      action: "Sent a Message",
      label: message,
    });
  };

  const handleChange = (e) => {
    const msg = e.target.value;
    setInputText(msg);
    handleUserMessage(msg);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  // Fallback UI if an error boundary is triggered
  const ErrorFallback = ({ error }) => {
    const parsedStack = parse(error.stack);
    logErrorToServer(error.message, parsedStack);
    return (
      <div role="alert" style={{ padding: "1rem", color: "red" }}>
        <h2>Something went wrong:</h2>
        <pre>{error.message}</pre>
      </div>
    );
  };

  // Logs the error details to your server
  const logErrorToServer = async (message, stack) => {
    try {
      await fetch("/api/log-error", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ message, stack }),
      });
    } catch (err) {
      console.error("Failed to log error to server:", err);
    }
  };

  // Reset entire conversation
  const handleReset = () => {
    setConversations([]);
    setSelectedConvIndex(null);
    setInputText("");
  };

  // Typically your backend URL or fallback
  const apiUrl = import.meta.env.VITE_APP_API_URL || "http://localhost:4000";

  // Send prompt to backend
  const handleSubmit = async () => {
    setIsLoading(true);
    setError(null);
    setRateLimitError(null);

    let endpoint;
    switch (selectedOption) {
      case "search":
        endpoint = "/api/send-message-search";
        break;
      case "gemini":
        endpoint = "/api/send-message-gemini";
        break;
      case "claude":
        endpoint = "/api/send-message-claude";
        break;
      case "perplexity":
        endpoint = "/api/send-message-perplexity";
        break;
      case "llama":
        endpoint = "/api/send-message-awsbedrock";
        break;
      case "phi":
        endpoint = "/api/send-message-phi";
        break;
      case "grok":
        endpoint = "/api/send-message-grok";
        break;
      default:
        endpoint = "/api/send-message-search";
        break;
    }

    try {
      const response = await fetch(`${apiUrl}${endpoint}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ message: inputText }),
      });

      if (response.status === 429) {
        throw new Error("Rate limit exceeded");
      }
      if (!response.ok) {
        throw new Error(`Error sending message: ${response.statusText}`);
      }

      let data;
      try {
        data = await response.json();
      } catch (err) {
        data = await response.text();
      }

      let assistantMessage = "";
      if (typeof data === "string") {
        assistantMessage = data;
      } else if (data?.content) {
        // Some APIs return an array in `content`
        assistantMessage = data.content[0]?.text || "No response.";
      } else if (data?.response) {
        assistantMessage = data.response;
      } else {
        assistantMessage = "No response from server.";
      }

      // Add new conversation: { prompt, answer }
      setConversations((prev) => [
        ...prev,
        { prompt: inputText, answer: assistantMessage },
      ]);

      // Select this new conversation so it's visible in main area
      const newIndex = conversations.length; // zero-based
      setSelectedConvIndex(newIndex);

      // Clear prompt input
      setInputText("");
    } catch (err) {
      console.error("Error:", err);
      if (err.message === "Rate limit exceeded") {
        setRateLimitError(
          "You have exceeded the maximum number of requests for today. Please try again tomorrow."
        );
      } else {
        setError("Failed to get response from the server.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Share the AI's answer
  const handleShare = (text) => {
    if (navigator.share) {
      navigator
        .share({ title: "AI Response", text })
        .catch((err) => console.error("Error sharing:", err));
    } else {
      navigator.clipboard.writeText(text).then(() => {
        alert("AI response copied to clipboard!");
      });
    }
  };

  // The conversation to show in the main area
  const activeConversation =
    selectedConvIndex !== null ? conversations[selectedConvIndex] : null;

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {/* 
        MAIN FLEX CONTAINER: Collapsible sidebar on the left, main content on the right
      */}
      <div style={{ display: "flex", height: "100vh", overflow: "hidden" }}>
        {/* COLLAPSIBLE SIDEBAR */}
        <div
          style={{
            width: sidebarOpen ? "250px" : "50px",
            backgroundColor: "#f5f5f5",
            borderRight: "1px solid #ddd",
            display: "flex",
            flexDirection: "column",
            transition: "width 0.3s ease",
          }}
        >
          {/* TOGGLE BUTTON (top) */}
          <button
            onClick={() => setSidebarOpen(!sidebarOpen)}
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
              padding: "0.5rem",
              textAlign: "left",
            }}
          >
            {sidebarOpen ? (
              <FontAwesomeIcon icon={faChevronLeft} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )}
          </button>

          {/* ONLY SHOW HISTORY LIST IF OPEN */}
          {sidebarOpen && (
            <div
              style={{ flex: 1, overflowY: "auto", padding: "0 0.5rem 1rem" }}
            >
              <h3>History</h3>
              {conversations.map((conv, idx) => (
                <div
                  key={idx}
                  onClick={() => setSelectedConvIndex(idx)}
                  style={{
                    marginBottom: "8px",
                    padding: "8px",
                    borderRadius: "5px",
                    backgroundColor:
                      idx === selectedConvIndex ? "#ddd" : "#fff",
                    cursor: "pointer",
                    border: "1px solid #ccc",
                  }}
                  title="Click to show the full prompt & answer"
                >
                  {/* Show only the user prompt here */}
                  {conv.prompt.slice(0, 70)}
                  {conv.prompt.length > 70 && "..."}
                </div>
              ))}
            </div>
          )}
        </div>

        {/* MAIN CONTENT AREA */}
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#fff",
          }}
        >
          <Header />

          {/* SCROLLABLE MAIN BODY */}
          <div style={{ flex: 1, overflowY: "auto", textAlign: "center" }}>
            <div className="inputWrapper" style={{ padding: "1rem" }}>
              {/* 
                If a conversation is selected, show its prompt & answer in full.
              */}
              {activeConversation && (
                <div style={{ marginBottom: "1rem" }}>
                  {/* User Prompt */}
                  <div style={{ marginBottom: "1rem" }}>
                    <strong>You:</strong>
                    <ChatMessage
                      message={{ text: activeConversation.prompt, user: true }}
                      isBot={false}
                    />
                  </div>

                  {/* AI Answer */}
                  <div>
                    <strong>Assistant:</strong>
                    <ChatMessage
                      message={{ text: activeConversation.answer, user: false }}
                      isBot={true}
                    />
                    {/* Share button for the answer */}
                    <button
                      onClick={() => handleShare(activeConversation.answer)}
                      className="button share-button"
                      style={{ marginTop: "5px" }}
                    >
                      <FontAwesomeIcon icon={faShareAlt} /> Share
                    </button>
                  </div>
                </div>
              )}

              {isLoading && <Spinner />}

              {/* PROMPT CONTROLS */}
              <div className="prompt-controls" style={{ marginBottom: "10px" }}>
                {/* Example links */}
                <div style={{ margin: "10px 0" }}>
                  <a
                    href="https://talk.my-docs.ai/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      marginRight: "15px",
                      color: "#8e44ad",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faComments}
                      style={{ marginRight: "5px" }}
                    />
                    Talk2Text AI
                  </a>
                  <a
                    href="http://translate.my-docs.ai/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#8e44ad",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faGlobe}
                      style={{ marginRight: "5px" }}
                    />
                    Translate AI
                  </a>
                </div>

                {/* Model selection dropdown */}
                <div
                  className="dropdown-container"
                  style={{ marginBottom: "10px" }}
                >
                  <label htmlFor="modelSelect" style={{ marginRight: "10px" }}>
                    Select a model:
                  </label>
                  <select
                    id="modelSelect"
                    value={selectedOption}
                    onChange={handleOptionChange}
                    style={{
                      padding: "5px",
                      backgroundColor: "#000",
                      color: "#fff",
                    }}
                  >
                    <option value="search">ChatGPT</option>
                    <option value="gemini">Google Gemini</option>
                    <option value="claude">Claude AI</option>
                    <option value="perplexity">Perplexity AI</option>
                    <option value="llama">Llama 3</option>
                    <option value="phi">Phi 3.5 Mini by Microsoft</option>
                    <option value="grok">xGrok AI</option>
                  </select>
                </div>

                {/* Prompt Textarea: 90% width with 5% margins */}
                <div
                  style={{
                    width: "90%",
                    marginLeft: "5%",
                    marginRight: "5%",
                    marginBottom: "10px",
                    position: "relative",
                    display: "inline-block",
                    textAlign: "left",
                  }}
                >
                  <textarea
                    placeholder="Ask a question..."
                    className="inputPrompt"
                    style={{
                      display: "block",
                      boxSizing: "border-box",
                      width: "100%",
                      minHeight: "150px",
                      paddingRight: "50px", // leave space for mic button
                    }}
                    value={inputText}
                    onChange={handleChange}
                  />
                  <button
                    type="button"
                    style={micButtonStyle(isListening)}
                    onClick={() => setIsListening(!isListening)}
                  >
                    <FontAwesomeIcon icon={faMicrophone} />
                  </button>
                </div>

                {/* Errors / Rate limit */}
                {error && <p style={{ color: "red" }}>{error}</p>}
                {rateLimitError && (
                  <p style={{ color: "red" }}>{rateLimitError}</p>
                )}
                {speechError && <p style={{ color: "red" }}>{speechError}</p>}

                {/* Send & Reset Buttons */}
                <div style={{ marginTop: "10px" }}>
                  <button
                    type="button"
                    className="button"
                    onClick={handleSubmit}
                  >
                    Send <FontAwesomeIcon icon={faPaperPlane} />
                  </button>
                  <button
                    type="button"
                    className="button reset-button"
                    onClick={handleReset}
                    style={{ marginLeft: "10px" }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* FOOTER */}
          <footer
            style={{
              flexShrink: 0,
              textAlign: "center",
              padding: "10px",
              backgroundColor: "#f9f9f9",
              borderTop: "1px solid #ccc",
            }}
          >
            <p style={{ margin: 0, color: "#000" }}>
              Made in <span style={{ color: "red" }}>❤️</span> with AI by{" "}
              <a
                href="https://www.linkedin.com/in/selvaonline/"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "#007BFF",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                Selvakumar Murugesan
              </a>
            </p>
          </footer>
        </div>
      </div>

      {/* Microphone animation */}
      <style>{`
        @keyframes micPulse {
          0%   { background-color: #333; }
          50%  { background-color: red;   }
          100% { background-color: #333; }
        }
      `}</style>
    </ErrorBoundary>
  );
};

export default ChatApp;
