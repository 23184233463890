import React, { useState, useEffect, useRef } from "react";
import ReactGA from "react-ga4";
import ChatMessage from "./ChatMessage";
import { useDropzone } from "react-dropzone";
import Header from "./Header";
import Spinner from "./Spinner";
import {
  faPaperPlane,
  faMicrophone,
  faComments,
  faGlobe,
  faShareAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorBoundary } from "react-error-boundary";
import { parse } from "stacktrace-parser";
import useSpeechRecognition from "../hooks/useSpeechRecognition";

// Mic button style
const micButtonStyle = (isListening) => ({
  position: "absolute",
  right: "10px",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 2,
  backgroundColor: "#333",
  color: "#fff",
  border: "none",
  cursor: "pointer",
  padding: "8px 12px",
  borderRadius: "4px",
  animation: isListening ? "micPulse 1.3s infinite" : "none",
});

const ChatApp = () => {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [rateLimitError, setRateLimitError] = useState(null);

  // Dropdown for model selection
  const [selectedOption, setSelectedOption] = useState("search");

  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);

  const {
    isListening,
    transcript,
    error: speechError,
    setIsListening,
  } = useSpeechRecognition();

  useEffect(() => {
    if (transcript) {
      setInputText(transcript);
    }
  }, [transcript]);

  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize("G-NFKD8XWG73");
    ReactGA.send("pageview");
  }, []);

  const handleUserMessage = (message) => {
    ReactGA.event({
      category: "User",
      action: "Sent a Message",
      label: message,
    });
  };

  const handleChange = (e) => {
    const msg = e.target.value;
    setInputText(msg);
    handleUserMessage(msg);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const ErrorFallback = ({ error }) => {
    const parsedStack = parse(error.stack);
    logErrorToServer(error.message, parsedStack);
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
      </div>
    );
  };

  const logErrorToServer = async (message, stack) => {
    try {
      await fetch("/api/log-error", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ message, stack }),
      });
    } catch (err) {
      console.error("Failed to log error to server:", err);
    }
  };

  const handleReset = () => {
    setMessages([]);
    setInputText("");
  };

  // Typically your backend URL or fallback
  const apiUrl = import.meta.env.VITE_APP_API_URL || "http://localhost:4000";

  const handleSubmit = async () => {
    setIsLoading(true);
    setError(null);
    setRateLimitError(null);

    let endpoint;
    switch (selectedOption) {
      case "search":
        endpoint = "/api/send-message-search";
        break;
      case "gemini":
        endpoint = "/api/send-message-gemini";
        break;
      case "claude":
        endpoint = "/api/send-message-claude";
        break;
      case "perplexity":
        endpoint = "/api/send-message-perplexity";
        break;
      case "llama":
        endpoint = "/api/send-message-awsbedrock";
        break;
      case "phi":
        endpoint = "/api/send-message-phi";
        break;
      // ** New Option for xGrok AI **
      case "grok":
        endpoint = "/api/send-message-grok";
        break;
      default:
        endpoint = "/api/send-message-search";
        break;
    }

    try {
      const response = await fetch(`${apiUrl}${endpoint}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ message: inputText }),
      });

      if (response.status === 429) {
        throw new Error("Rate limit exceeded");
      }
      if (!response.ok) {
        throw new Error(`Error sending message: ${response.statusText}`);
      }

      let data;
      try {
        data = await response.json();
      } catch (err) {
        data = await response.text();
      }

      let assistantMessage = "";
      if (typeof data === "string") {
        assistantMessage = data;
      } else if (data.content) {
        assistantMessage = data.content[0]?.text || "No response.";
      } else if (data.response) {
        assistantMessage = data.response;
      } else {
        assistantMessage = "No response from server.";
      }

      setMessages((prev) => [
        ...prev,
        { text: inputText, user: true },
        { text: assistantMessage, user: false },
      ]);
      setInputText("");
    } catch (err) {
      console.error("Error:", err);
      if (err.message === "Rate limit exceeded") {
        setRateLimitError(
          "You have exceeded the maximum number of requests for today. Please try again tomorrow."
        );
      } else {
        setError("Failed to get response from the server.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleShare = (text) => {
    if (navigator.share) {
      navigator
        .share({ title: "AI Response", text })
        .catch((err) => console.error("Error sharing:", err));
    } else {
      navigator.clipboard.writeText(text).then(() => {
        alert("AI response copied to clipboard!");
      });
    }
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div style={{ width: "100%", margin: "0 auto", textAlign: "center" }}>
        <Header />

        <div className="inputWrapper">
          {(messages.length > 0 || isLoading) && (
            <div className="chat-messages" style={{ marginBottom: "1rem" }}>
              {messages.map((message, idx) => (
                <div key={idx} style={{ marginBottom: "1rem" }}>
                  <ChatMessage message={message} isBot={!message.user} />
                  {/* Share button if it's bot's message */}
                  {!message.user && (
                    <button
                      onClick={() => handleShare(message.text)}
                      className="button share-button"
                      style={{ marginTop: "5px" }}
                    >
                      <FontAwesomeIcon icon={faShareAlt} /> Share
                    </button>
                  )}
                </div>
              ))}
              {isLoading && <Spinner />}
            </div>
          )}

          <div className="prompt-controls" style={{ marginBottom: "10px" }}>
            {/* Hyperlinks */}
            <div style={{ margin: "10px 0" }}>
              <a
                href="https://talk.my-docs.ai/"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  marginRight: "15px",
                  color: "#8e44ad",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                <FontAwesomeIcon
                  icon={faComments}
                  style={{ marginRight: "5px" }}
                />
                Talk2Text AI
              </a>
              <a
                href="http://translate.my-docs.ai/"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "#8e44ad",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                <FontAwesomeIcon
                  icon={faGlobe}
                  style={{ marginRight: "5px" }}
                />
                Translate AI
              </a>
            </div>

            <div
              className="dropdown-container"
              style={{ marginBottom: "10px" }}
            >
              <label htmlFor="modelSelect" style={{ marginRight: "10px" }}>
                Select a model:
              </label>
              <select
                id="modelSelect"
                value={selectedOption}
                onChange={handleOptionChange}
                style={{
                  padding: "5px",
                  backgroundColor: "#000",
                  color: "#fff",
                }}
              >
                <option value="search">ChatGPT</option>
                <option value="gemini">Google Gemini</option>
                <option value="claude">Claude AI</option>
                <option value="perplexity">Perplexity AI</option>
                <option value="llama">Llama 3</option>
                <option value="phi">Phi 3.5 Mini by Microsoft</option>
                {/* NEW: xGrok AI */}
                <option value="grok">xGrok AI</option>
              </select>
            </div>

            {/* Updated width, margins, and textarea height */}
            <div
              style={{
                width: "75%",
                marginLeft: "12.5%",
                marginRight: "12.5%",
                marginBottom: "10px",
                position: "relative",
                display: "inline-block",
                textAlign: "left",
              }}
            >
              <textarea
                placeholder="Ask a question..."
                className="inputPrompt"
                style={{
                  display: "block",
                  boxSizing: "border-box",
                  width: "100%",
                  minHeight: "150px",
                  paddingRight: "50px", // room for mic button
                }}
                value={inputText}
                onChange={handleChange}
              />
              <button
                type="button"
                style={micButtonStyle(isListening)}
                onClick={() => setIsListening(!isListening)}
              >
                <FontAwesomeIcon icon={faMicrophone} />
              </button>
            </div>

            {/* Error messages */}
            {error && <p style={{ color: "red" }}>{error}</p>}
            {rateLimitError && <p style={{ color: "red" }}>{rateLimitError}</p>}
            {speechError && <p style={{ color: "red" }}>{speechError}</p>}

            {/* Send & Reset */}
            <div style={{ marginTop: "10px" }}>
              <button type="button" className="button" onClick={handleSubmit}>
                Send <FontAwesomeIcon icon={faPaperPlane} />
              </button>
              <button
                type="button"
                className="button reset-button"
                onClick={handleReset}
                style={{ marginLeft: "10px" }}
              >
                Reset
              </button>
            </div>
          </div>
        </div>

        <footer
          style={{
            flexShrink: 0,
            textAlign: "center",
            padding: "10px",
          }}
        >
          <p style={{ margin: 0, color: "#000" }}>
            Made in <span style={{ color: "red" }}>❤️</span> with AI by{" "}
            <a
              href="https://www.linkedin.com/in/selvaonline/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#007BFF",
                textDecoration: "none",
                fontWeight: "bold",
              }}
            >
              Selvakumar Murugesan
            </a>
          </p>
        </footer>

        {/* Mic animation */}
        <style>{`
          @keyframes micPulse {
            0%   { background-color: #333; }
            50%  { background-color: red;   }
            100% { background-color: #333; }
          }
        `}</style>
      </div>
    </ErrorBoundary>
  );
};

export default ChatApp;
