import React from "react";

const Header = () => {
  return (
    <header className="chat-header">
      <img
        src="/my-docs-logo.png"
        style={{ width: "150px", height: "150px" }}
        alt="my-docs.ai"
      />
    </header>
  );
};

export default Header;
